import React from 'react';
import {Spinner} from "react-bootstrap";

const Loader = () => {
    return (
        <div className="main-loader flexing min-vh-100">
            <Spinner animation="border" variant="warning"/>
        </div>
    );
}

export default Loader;
