import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import './BackButton.css';
import {IoChevronBack} from "react-icons/io5";

const BackButton = ()  => {
    const navigate = useNavigate();
    let location = useLocation();
    return (
      <>
          {location.pathname !== '/' && location.pathname !== '/profile' &&
          <div className="back-button-inner position-absolute ms-2 mt-2">
              <div onClick={() => navigate(-1)}
                   className="btn-position flexing text-white bg-black rounded-circle pointer opacity-50">
                  <IoChevronBack className="icon fw-bold fs-5"/>
              </div>
          </div>
          }
          {location.pathname === '/profile' &&
          <div className="back-button-inner position-absolute ms-2 mt-2">
              <div onClick={() => navigate('/')}
                   className="btn-position flexing text-white bg-black rounded-circle pointer opacity-50">
                  <IoChevronBack className="icon fw-bold fs-5"/>
              </div>
          </div>
          }
      </>
    );
}

export default BackButton;
