import React from 'react';
import {Button, Modal} from "react-bootstrap";
import './JoinDialog.css';
import {useNavigate} from "react-router-dom";

const JoinDialog = (props)  => {
    const navigate = useNavigate();
    const redirectJoinBtn = () => {
        navigate('/register');
        props.onHide();
    }
    const redirectLoginBtn = () => {
        navigate('/login');
        props.onHide();
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="text-black"
        >
            <Modal.Header closeButton/>
            <Modal.Body>
                <h4 className="text-center fw-bold">You need to join now in order to access all features of the app?</h4>
            </Modal.Body>
            <Modal.Footer className="flexing gap-2">
                <Button className="btn ripple joinBtn rounded-pill" onClick={redirectJoinBtn}>Join Now</Button>
                <Button className="btn ripple joinBtn bg-black text-white rounded-pill" onClick={redirectLoginBtn}>Sign In</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default JoinDialog;
