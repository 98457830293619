import {useEffect, useState} from "react";
import {getRefreshToken} from "../http";
import {useNavigate} from "react-router-dom";
import {socketConnect} from "../socket/SocketWrapper";

export function useLoadingWithRefresh() {
    const [loading, setLoading] = useState(true);
    const refreshToken = localStorage.getItem('web_refreshToken');

    const autoLogout =() => {
        localStorage.removeItem('web_user');
        localStorage.removeItem('web_access_token');
        localStorage.removeItem('web_refreshToken');
        localStorage.removeItem('web_fireBase_token');
    }

    useEffect(() => {
        (async () => {
            try {
                if (refreshToken) {
                    const {data} = await getRefreshToken({refreshToken});
                    localStorage.setItem('web_access_token', data.token);
                    setLoading(false);
                }
            } catch (err) {
                await autoLogout();
                setLoading(false);
            }
        })();
    }, []);

    return {loading};
}
