import axios from 'axios';
import {constants} from "../config/constants";

const api = () => {
    const token = localStorage.getItem('web_access_token')
    return axios.create({
        baseURL: constants.BASE_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
}

//List of index endpoints
//auth and profile section
export const register = (data) => api().post('/api/auth/register', data);
export const login = (data) => api().post('/api/auth/login', data);
export const getRefreshToken = (data) => api().post('/api/auth/token-refresh', data);
export const updateProfile = (data) => api().put('/api/auth/profile', data);
export const updatePassword = (data) => api().put('/api/auth/password-change', data);
export const getPrivacyPolicy = () => api().get('/api/privacy-policy/');

//friends section
export const getUsers = (page) => api().get(`/api/users?page=${page}&perPage=21&isAdmin=false`);
export const getUserSearch = (data) => api().get(`/api/users?displayName=${data.keyword}&page=${data.page}&perPage=21&isAdmin=false`);
export const getFriendsSearch = (data) => api().get(`/api/friends?displayName=${data.keyword}&page=${data.page}&perPage=20&isAdmin=false`);
export const getFriends = (page, perPage) => api().get(`/api/friends?page=${page}&perPage=${perPage}`);
export const removeFriend = (id) => api().delete(`/api/friends/${id}`);
export const getUserDetails = (id) => api().get(`/api/users/${id}`);
export const getMutualFriend = (id) => api().get(`/api/friends/${id}/mutual`);
export const sendFriendRequest = (data) => api().post('/api/friends-requests', data);
export const getFriendRequest = (page, perPage) => api().get(`/api/friends-requests?page=${page}&perPage=${perPage}`);
export const acceptFriendRequest = (id) => api().post(`/api/friends-requests/${id}`);
export const declineFriendRequest = (id) => api().delete(`/api/friends-requests/${id}`);
export const reportUser = (data) => api().post('/api/report/user', data);

//feed data
export const getFeedData = () => api().get(`/api/feed/meta/${constants.INSTANCE_INFO.APP_CLUB_ID}`);

//wall post section
export const getWallPost = (data) => api().get(`/api/posts/${data}`);
export const createPost = (data) => api().post(`/api/wall`, data);
export const getWallPostComments = (data) => api().get(`/api/posts/${data}/comments?page=1&perPage=10`);
export const wallPostComment = (data) => api().post('/api/wall-comments', data);
export const updateWallPostComment = (id, data) => api().put(`/api/wall-comments/${id}`, data);
export const deleteWallPostComment = (id) => api().delete(`/api/wall-comments/${id}`);
export const updateWallPostLike = (id, data) => api().put(`/api/posts/${id}/likes`, data);

//news post section
export const getNewsList = (page) => api().get(`/api/news?page=${page}&perPage=20`);
export const getNews = (data) => api().get(`/api/news/${data}`);
export const getNewsComments = (data) => api().get(`/api/news/${data}/comments?page=1&perPage=10`);
export const newsComment = (data) => api().post('/api/news-comments', data);
export const updateNewsComment = (id, data) => api().put(`/api/news-comments/${id}`, data);
export const deleteNewsComment = (id) => api().delete(`/api/news-comments/${id}`);
export const updateNewsLike = (id, data) => api().put(`/api/news/${id}/likes`, data);

//rumors post section
export const getRumorsList = (page) => api().get(`/api/rumors?page=${page}&perPage=18`);
export const getRumors = (data) => api().get(`/api/rumors/${data}`);
export const getRumorsComments = (data) => api().get(`/api/rumors/${data}/comments?page=1&perPage=10`);
export const rumorsComment = (data) => api().post('/api/rumors-comments', data);
export const updateRumorsComment = (id, data) => api().put(`/api/rumors-comments/${id}`, data);
export const deleteRumorsComment = (id) => api().delete(`/api/rumors-comments/${id}`);
export const updateRumorsLike = (id, data) => api().put(`/api/rumors/${id}/likes`, data);

//social post section
export const getSocialList = (page) => api().get(`/api/social?page=${page}&perPage=18`);
export const getSocial = (data) => api().get(`/api/social/${data}`);
export const getSocialComments = (data) => api().get(`/api/social/${data}/comments?page=1&perPage=10`);
export const socialComment = (data) => api().post('/api/social-comments', data);
export const updateSocialComment = (id, data) => api().put(`/api/social-comments/${id}`, data);
export const deleteSocialComment = (id) => api().delete(`/api/social-comments/${id}`);
export const updateSocialLike = (id, data) => api().put(`/api/social/${id}/likes`, data);

//videos post section
export const getVideosList = (page) => api().get(`/api/videos?page=${page}&perPage=18`);
export const getVideos = (data) => api().get(`/api/videos/${data}`);
export const getVideosComments = (data) => api().get(`/api/videos/${data}/comments?page=1&perPage=10`);
export const videosComment = (data) => api().post('/api/video-comments', data);
export const updateVideosComment = (id, data) => api().put(`/api/video-comments/${id}`, data);
export const deleteVideosComment = (id) => api().delete(`/api/video-comments/${id}`);
export const updateVideosLike = (id, data) => api().put(`/api/videos/${id}/likes`, data);

export const passwordReset = (id, data) => api().post(`/api/auth/password-reset/${id}`, data);
export const passwordResetRequest = (data) => api().post(`/api/auth/password-reset`,data);

export default api;
