import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from "./reportWebVitals";
import {constants} from "./config/constants";
// import './assets/css/themes/1680.css'

if (constants.INSTANCE_INFO.APP_CLUB_ID === '1680') {
    import ('./assets/css/themes/1680.css');
} else if (constants.INSTANCE_INFO.APP_CLUB_ID === '2001') {
    import ('./assets/css/themes/2001.css');
}

document.getElementById('title').innerText = `${constants.INSTANCE_INFO.APP_TITLE}`;
const favicon = document.getElementById("faviconId");
favicon.href = `${constants.INSTANCE_INFO.APP_FAVICON}`;
ReactDOM.render(
        <App/>,
    document.getElementById('root')
);
reportWebVitals();
