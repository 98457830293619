import React, {useContext, useEffect, useState} from 'react';
import {constants} from "../../config/constants";
import './Header.css';
import {Container, Navbar} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import ImageTag from "../shared/ImageTag";
import {IoPeopleSharp} from "react-icons/io5";
import {SocketContext} from "../../socket/SocketWrapper";

const web_notify = localStorage.getItem('web_notify');
const Header = () => {
    const socket = useContext(SocketContext);
    const data = localStorage.getItem('web_user');
    const user = JSON.parse(data);
    const [notify, setNotify] = useState(false);
    const location = useLocation();
    const noty = localStorage.getItem('web_dots');  // 0-true // 1-false

    useEffect(() => {
        if (user && socket) {
            const manegeNotification = (data) => setNotify(prevMessages => {
                if (data.event === "friendsRequestReceived") {
                    localStorage.setItem('web_dots', 0);
                    return true
                } else {
                    localStorage.setItem('web_dots', 1);
                    return false;
                }
            });
            socket.on('notification', manegeNotification);
        }
    }, [user, socket]);
    //
    // useEffect(() => {
    //     if (location.pathname === '/friends') localStorage.setItem('web_dots', 1);
    // }, [noty])
    //
    //
    // console.log(notify);

    return (
        <>
            <Navbar className="d-flex justify-content-between align-items-center">
                <Container>
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                src={`/assets/images/logos/${constants.INSTANCE_INFO.APP_MAIN_LOGO}`}
                                alt="logo"
                                width="130"
                                height="40"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                    </Link>
                    <div className="d-flex align-items-center justify-content-center gap-3 gap-sm-4">
                        <div id="google_translate_element"/>
                        <Link to="/friends">
                            <IoPeopleSharp className="fs-2 position-relative text-black-white pointer"/>
                            {noty === '0' && <div className="position-absolute eclipse"/>}
                        </Link>
                        <Link to="/login">
                            <ImageTag
                                _image={user?.avatarUrl || '/assets/images/blank_profile.png'}
                                alt={"logo"}
                                _class={'main-avatar d-inline-block align-top rounded-circle'}
                                isProfile={true}
                            />
                        </Link>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
