import React, {useEffect, useState} from 'react';
import {Carousel} from "react-bootstrap";
import '../Ticker/ticker.css';

const Ticker = () => {
    const [tickers, setTickers] = useState([]);
    const _tickers = localStorage.getItem('web_ticker');
    useEffect(() => {
        if(_tickers){
            setTickers(JSON.parse(_tickers));
        }
    }, [_tickers]);
    return (
        <Carousel controls={false} indicators={false} interval={5000}
                  className="flexing tickerBg">
            {tickers.map((ticker, index) => (
                <Carousel.Item className="text-center p-1" key={index}>
                    {ticker}
                </Carousel.Item>
            ))}

        </Carousel>
    );
}

export default Ticker;
