import {createContext} from 'react';
import {io} from "socket.io-client";
import {constants} from "../config/constants";

export const socketConnect = (token) => {
  if (token) {
    const socket = io(constants.EVENT_URL, {
      transports: ['websocket'],
      auth: { token },
    });
    return socket;
  }
  return null;
};

export const SocketContext = createContext({
  socket: null,
});

